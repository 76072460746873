import React from "react";

const ImageLabel = ({ label }) => {

  return (
    <div className="font-medium mt-4 w-auto relative">
      <div className="text-primary-500 relative z-[1] font-bold">
        {label}
      </div>
      <div className="w-[50%] bg-primary-100 px-4 rounded-xl absolute h-100 my-auto top-0 bottom-0 left-[-16px]"></div>
    </div>
  );
};

export default ImageLabel;