/* eslint-disable react/jsx-no-target-blank */
import React from "react";
import "./styles.scss";
import EntLogoLight from "../../assets/icons/ent-logo-light.svg";
import EntRightChevron from "../../assets/imgs/ent-right-chevron.png"
import EntMag from "../../assets/icons/ent-mag.svg";
import { SOCIAL_ICONS } from "../../utils/footer";
import { NavLink } from "react-router-dom";

const Footer = () => {
  return (
    <footer className={"bg-primary-500 sm:pb-10 pb-4"}>
      <div className={"container sm:pt-24 pt-4"}>
        <div className={"w-full grid xl:grid-cols-11 xl:gap-12 gap-8 sm:grid-cols-4 grid-cols-1 sm:text-left text-center"}>
          <div className={"w-full xl:col-span-3"}>
            <div className="sm:block flex flex-col items-center sm:mt-0 mt-10">
              <a href={"/#"}>
                <img
                  className={"lg:h-8 h-6"}
                  src={EntLogoLight}
                  alt={"Logo"}
                />
              </a>
            </div>
            <p className="font-light text-white mt-6">Hassle - Free International Money Transfers to Nigeria. Easily Send Funds to Nigeria from Anywhere Across the Globe!</p>
          </div>
          <div className={"w-full sm:mt-0 mt-10 xl:col-span-2 xl:pl-20"}>
            <p className={"font-light text-body-lg text-white"}>
              OUR MISSION
            </p>
            <div className="text-body-md text-body-lg mt-4">
              <NavLink to={"#/"}>
                <p className="text-white font-light">Why Choose Entrusted</p>
              </NavLink>
              <NavLink to={"#/"}>
                <p className="text-white font-light mt-2">Who We Are</p>
              </NavLink>
              <NavLink to={"#/"}>
                <p className="text-white font-light mt-2">What We Do</p>
              </NavLink>
              <NavLink to={"#/"}>
                <p className="text-white font-light mt-2">Partnerships</p>
              </NavLink>
              <NavLink to={"#/"}>
                <p className="text-white font-light mt-2">Mission & Vision</p>
              </NavLink>
            </div>
          </div>
          <div className={"w-full sm:mt-0 mt-10 xl:col-span-3 xl:pl-10"}>
            <p className={"font-light text-body-lg text-white"}>
              CONTACT US
            </p>
            <div className="text-body-md text-body-lg mt-4">
              <p className="text-white font-light">Plot 1014 Fria Close Wuse II Abuja 5 Onitsha Crescent, Garki, Abuja</p>
              <p className="text-white font-light mt-4">info@entrusted.com</p>
              <p className="text-white font-light mt-4">+234 708 579 1804</p>
            </div>
          </div>
          <div className={"w-full sm:mt-0 mt-10 xl:col-span-3"}>
            <div className="font-normal">
              <p className="text-white font-light">Stay industry connected with our monthly newsletter</p>
            </div>
            <div className="flex items-center relative">
              <input type="email" className="bg-transparent border-b-[1px] border-b-white w-full my-6" placeholder="Email Address" />
              <img src={EntRightChevron} alt="icon" className="h-4 absolute right-0 top-[2.5rem]" />
            </div>
            <div className={"mt-2 sm:mt-6"}>
              <div className={"sm:mr-10 mr-0 sm:mt-0 mt-4 mb-8"}>
                <ul className={"flex gap-x-4"}>
                  {SOCIAL_ICONS.map((si, i) => (
                    <a
                      key={i + "icon"}
                      href={si.url}
                      className={"footer-icon"}
                    >
                      <img alt={"instagram"} src={si.icon} />
                    </a>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="border-t-[0.5px] mt-10 border-t-primary-100">
        <div className={"container mt-6"}>
          <div className="flex items-center justify-between">
            <p className="text-white font-light text-body-sm">Copyright © 2023 Entrusted Nigeria Limited</p>
            <div className="lg:col-span-2 text-right flex items-center lg:justify-end gap-x-4 lg:my-0 my-10">
              <p className="text-white font-light text-body-xs">An affiliate of</p>
              <a target="_blank" href="https://maggrouplimited.com">
                <img
                  className={"lg:h-auto h-10"}
                  src={EntMag}
                  alt={"Logo"}
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
