import React, { useEffect, useState } from "react";
import { Transition } from "@headlessui/react";
import EntMenuOpen from "../../assets/imgs/ent-close-icon.png";
import EntMenuClose from "../../assets/icons/ent-menu-close.svg";
import EntLogo from "../../assets/icons/ent-logo.svg";
import EntLogoLight from "../../assets/icons/ent-logo-light.svg";
import "./styles.scss";
import { NavLink } from "react-router-dom";
import { navs } from "../../utils/navs";
import StaticButton from "../Button/StaticButton";

const NavBar = ({ className }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);

  const handleClickScroll = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  useEffect(() => {
    window.onscroll = function () {
      if (window.scrollY > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };
  }, []);

  return (
    <div className={"fixed w-full z-20"}>
      <nav className={`${scrolled ? "nav-bar-floated bg-white" : "bg-transparent"}`}>
        <div className={"container"}>
          <div className={"flex items-center justify-between h-16 sm:h-20"}>
            <div className={"flex items-center"}>
              <div className={"flex-shrink-0"}>
                <NavLink href={"#headerSection"} onClick={() => handleClickScroll("headerSection")}>
                  <img
                    className={"lg:h-8 h-6"}
                    src={EntLogo}
                    alt={"Logo"}
                  />
                </NavLink>
              </div>
            </div>
            <div className={"flex items-center"}>
              <div className={"hidden md:block"}>
                <div className={"ml-10 flex items-baseline lg:space-x-10"}>
                  {navs.map((n, i) => (
                    <NavLink
                      className={"nav_style lg:text-body-md text-body-sm"}
                      key={i}
                      to={n.route}
                      onClick={() => handleClickScroll(n.id)}
                    >
                      {n.name}
                    </NavLink>
                  ))}
                  <StaticButton text={"Get Started"} />
                </div>
              </div>
            </div>
            <div className={"-mr-2 flex md:hidden"}>
              <button
                onClick={() => setIsOpen(!isOpen)}
                type={"button"}
                className={
                  "inline-flex items-center justify-center p-2 focus:outline-none"
                }
                aria-controls={"mobile-menu"}
                aria-expanded={"false"}
              >
                <img src={EntMenuClose} alt={"menu"} className={"h-8"} />
              </button>
            </div>
          </div>
        </div>

        <Transition
          show={isOpen}
          enter={"transition ease-out duration-100 transform"}
          enterFrom={"opacity-0 scale-95"}
          enterTo={"opacity-100 scale-100"}
          leave={"transition ease-in duration-75 transform"}
          leaveFrom={"opacity-100 scale-100"}
          leaveTo={"opacity-0 scale-95"}
          className={"absolute w-full bg-primary-500 h-[100vh] shadow top-0"}
        >
          <div className={"md:hidden"} id={"mobile-menu"}>
            <div className={"-mr-2 flex md:hidden justify-between p-4 items-center"}>
              <img
                className={"h-5"}
                src={EntLogoLight}
                alt={"Logo"}
              />
              <button
                onClick={() => setIsOpen(!isOpen)}
                type={"button"}
                className={
                  "inline-flex items-center justify-center p-2 focus:outline-none"
                }
                aria-controls={"mobile-menu"}
                aria-expanded={"false"}
              >
                <img src={EntMenuOpen} alt={"menu"} className={"h-8"} />
              </button>
            </div>
            <div className={"px-2 pt-2 pb-3 space-y-1 sm:px-3 text-center"}>
              {navs.map((n, i) => (
                <div key={i + n}>
                  <NavLink
                    className={"nav_style_mobile lg:text-body-md text-body-sm"}
                    key={i}
                    to={n.route}
                    onClick={() => { setIsOpen(!isOpen); handleClickScroll(n.id) }}
                  >
                    {n.name}
                  </NavLink>
                </div>
              ))}
            </div>
            <div className="flex justify-center">
              <StaticButton text={"Get Started"} />
            </div>
          </div>
        </Transition>
      </nav>
    </div>
  );
};

export default NavBar;
