import EntInstagram from "../assets/imgs/ent-instagram.png";
import EntTwitter from "../assets/imgs/ent-twitter.png";
import EntFacebook from "../assets/imgs/ent-facebook.png";
import EntLinkedIn from "../assets/imgs/ent-linkedin.png";
const SOCIAL_ICONS = [
  {
    icon: EntInstagram,
    url: "/#",
  },
  {
    icon: EntTwitter,
    url: "/#",
  },
  {
    icon: EntFacebook,
    url: "/#",
  },
  {
    icon: EntLinkedIn,
    url: "/#",
  },
];

export { SOCIAL_ICONS };
