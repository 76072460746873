import React from "react";
import EntRightChevron from "../../assets/imgs/ent-right-chevron.png"
import "./styles.scss";

const StaticButton = (props) => {
  const { text = "Button", state = "PRIMARY", ...rest } = props;
  return (
    <div className="relative">
      <button className={state === "PRIMARY" ? "static-button-primary" : "static-button-secondary"} {...rest}>
        {text}
        <img src={EntRightChevron} alt="icon"/>
      </button>
    </div>
  );
};

export default StaticButton;