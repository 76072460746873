import React from "react";

const CookieSettings = ({ handleClose, handleAccept }) => {
  return (
    <div className="w-full">
      <div className="flex gap-x-4 items-center cursor-pointer py-2 sm:text-body-2xl text-body-xl font-semibold text-white">
        Customize your cookie settings
      </div>
      <p className="text-white opacity-[0.5] sm:text-body-lg text-body-md mb-5">This website uses cookies</p>
      <p className="text-white sm:text-body-lg text-body-md mb-5">We use cookies to offer useful features and measure performance to improve your experience.</p>
      <div className="text-white sm:text-body-lg text-body-md">
        · By clicking <span className="text-white font-semibold">Accept all</span> you agree to the use of all cookies.
      </div>
      <div className="text-white sm:text-body-lg text-body-md mb-5">
        · By clicking <span className="text-white font-semibold">Accept selected</span>, you agree only to the categories you have selected.
      </div>
      <div className="text-white sm:text-body-lg text-body-md mb-6">
        You can find further information in our <span className="underline text-white cursor-pointer">Privacy Policy</span>
      </div>
      <div className="sm:flex justify-between mb-16">
        <div className="flex items-center form-group">
          <input checked readOnly id="checked1" type="checkbox" value="checked1" />
          <label htmlFor="checked1" className="sm:text-body-lg text-body-md font-medium"><span className="opacity-[0.7] text-white">Necessary</span></label>
        </div>
        <div className="flex items-center form-group">
          <input id="checked2" type="checkbox" value="checked2" />
          <label htmlFor="checked2" className="sm:text-body-lg text-body-md font-medium text-white">Performance</label>
        </div>
        <div className="flex items-center form-group">
          <input id="checkedw" type="checkbox" value="" />
          <label htmlFor="checkedw" className="sm:text-body-lg text-body-md font-medium text-white">Statistics</label>
        </div>
      </div>
      <div className="flex sm:justify-end justify-between gap-x-4">
        <button onClick={handleAccept} className="rounded-[19px] px-5 py-3 border-[2px] border-[#FFFFFFB3]"><span className="opacity-[0.7] text-white font-semibold">Accept Selected</span></button>
        <button onClick={handleAccept} className="rounded-[19px] px-5 py-3 bg-white"><span className="text-primary-500 font-semibold">Accept All</span></button>
      </div>
    </div>
  );
};

export default CookieSettings;
