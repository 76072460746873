const navs = [
  {
    name: "Why Choose Entrusted",
    route: "#whyChooseEntrusted",
    id: "whyChooseEntrusted",
  },
  {
    name: "About Us",
    route: "#aboutUs",
    id: "aboutUs",
  },
  {
    name: "Contact Us",
    route: "#contactUs",
    id: "contactUs",
  },
];

export { navs };
